<template>
  <vue-modal @close="$emit('saved'),$emit('close')" ref="modal" size="lg">
    <template #title>Galería</template>
    <template #body>
      <div class="row justify-content-around flex-wrap">
        <div style="width: 150px">
          <FormSwitch
          style="min-width:150px"
          v-model="galeria_img"
          label="Nueva Galeria?"
          icon="fa-solid fa-star"
          />
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center flex-wrap align-items-center mb-3" style="min-height: 84px">
        <template v-if="galeria_img">
          <h6 class="text-center">Cree una Nueva Galeria y Seleccione las Imagenes que desea guardar en la nueva Galeria</h6>  
          <div class="row align-items-baseline">
            <FormText
            style="min-width:250px"
            v-model="form.new_galeria"
            icon="fa-solid fa-tag"
            label="Nombre de la nueva Galeria"
            :rules="{ required: true }"
            />
            <button class="btn btn-primary button-crear-galeria ml-2" type="button" @click="createNewGaleria">Crear</button>
          </div> 
          
        </template>
        <template v-else>
          <h6 class="text-center">Seleccione una Galeria y Seleccione las Imagenes que desea guardar en la Galeria</h6>
          <div class="row align-items-center">
            <FormSelect 
            style="min-width:150px"
            label="Galerías"
            icon="fa-solid fa-images"
            :options="listGalerias"
            v-model="form.selected_galeria"
            :rules="{ required: true }"
          />
          </div>
        </template>
      </div>
      <vue-table-boostrap>
        <template #headers>
          <tr class="text-center">
            <th v-for="title in ['Selección','MEDIO', 'Socio', 'Descrición', 'Fecha Evento']" :key="title" scope="col">{{ title }}</th>
          </tr>
        </template>
        <template #rows>
          <template v-for="item in items" :key="item.id">
            <tr class="text-center" v-for="img in item.file_img_publicas" :key="img">
              <td class="min" scope="row"><CheckBoxComponent :item="img" class="" @checkBox="checkBoxImg"/></td>
              <td>
                <div class="style-galeria-img position-relative">
                  <img :src="img.url" alt="">
                  <i class="fa-solid fa-trash icon-trash-delete-img position-absolute" @click="onDelete('img',img)"></i>
                  <i class="fa-solid fa-magnifying-glass icon-search-img position-absolute" @click="onZoom('img', img)"></i>
                </div>
              </td>
              <td>{{item.socio}}</td>
              <td>{{item.descripcion}}</td>
              <td>{{moment(item.fecha).format('DD/MM/YYYY')}}</td>
            </tr>
            <tr class="text-center" v-for="video in item.file_video_publicas" :key="video">
              <th class="min" scope="row"><CheckBoxComponent :item="video" class="" @checkBox="checkBoxVideo"/></th>
              <td>
                 <div class="style-galeria-video position-relative">
                  <video :src="video.url" autoplay muted loop></video>
                  <i class="fa-solid fa-trash icon-trash-delete-video position-absolute" @click="onDelete('video',video)"></i>
                  <i class="fa-solid fa-magnifying-glass icon-search-video position-absolute" @click="onZoom('video', video)"></i>
                </div>
              </td>
              <td>{{item.socio}}</td>
              <td>{{item.descripcion}}</td>
              <td>{{moment(item.fecha).format('DD/MM/YYYY')}}</td>
            </tr>
          </template>
        </template>
      </vue-table-boostrap>
      <div class="d-flex flex-column justify-content-center flex-wrap align-items-center mt-3" style="min-height: 84px">
        <h6 class="text-center">Si Desea eliminar una Galeria Publica del Usuario, Seleccionela y aprete el Tacho de Basura</h6>
        <div class="row align-items-baseline">
          <FormSelect 
          style="min-width:150px"
          label="Galerías Publica"
          icon="fa-solid fa-images"
          :options="listGaleriasPublicas"
          v-model="form.selected_galeria_publica"
          :rules="{ required: true }"
        />
        <button type="button" class="btn btn-outline-primary ml-2" @click="onDeleteGaleriaPublica"><i class="fa-solid fa-trash" ></i></button>
        </div>
      </div>
        <div v-if="zoom" class="position-fixed zoom-items" @click="onZoom('close', $event)">
          <img class="position-absolute zoom-items-img" v-if="img_zoom" :src="img_zoom" alt="">
          <video id="video-zoom" class="position-absolute zoom-items-video" v-if="video_zoom" :src="video_zoom" controls></video>
        </div>

    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormSwitch from "@/components/Form/FormSwitch.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import CheckBoxComponent from '@/components/Form/CheckBoxComponent.vue';
import moment from "moment";

export default {
  components: {
    FormText,
    FormSwitch,
    FormSelect,
    CheckBoxComponent,
},
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    form: {
      new_galeria_id: null,
      new_galeria: null,
      selected_galeria: null,
      selected_galeria_publica: null,
    },
    form_img:[],
    form_video:[],
    img_zoom: null,
    video_zoom: null,
    zoom: false,
    galeria_img: true,
  }),
  computed: {
    listGalerias(){
      return this.$store.getters.listaGalerias.map(c => ({value: c.id, name: c.nombre})) || []
    },
    listGaleriasPublicas(){
      return this.$store.getters.filesPublicos.map(c => ({value: c.id, name: c.descripcion})) || []
    },
    items() {
      return this.$store.getters.filesPublicos;
    },
  },
  async mounted() {
    await this.$store.dispatch('getListaGalerias')
    await this.getItems();
  },
  methods: {
    moment: function () {
    return moment();
    },
    close() {
      this.$refs.modal.close();
    },
    async getItems() {
      await this.$store.commit("CLEAR_FILES_PUBLICOS");
      if (this.id){
        await this.$store.dispatch("getFilesPublicosByName", {id: this.id});
      }
      await this.$store.dispatch('getListaGalerias')
    },
    async createNewGaleria(){
      let form = {nombre: this.form.new_galeria}
      await this.$store.dispatch("createGaleria",form)
      .then(({data})=>{
        this.form.new_galeria_id = data.galeria.id,
        this.form.selected_galeria = null
      })
      .finally(()=>{
        this.$store.dispatch('getListaGalerias')
      })
    },
    checkBoxImg(value, item){
      if(!value){
        return this.form_img = this.form_img.filter(x=> x.url !== item.url)
      }
      this.form_img.push(item)
    },
    checkBoxVideo(value, item){
      if(!value){
        return this.form_video = this.form_video.filter(x=> x.url !== item.url)
      }
      this.form_video.push(item)
    },
    onZoom(type, item){
      if(type === 'img'){
        return this.img_zoom = item.url, this.zoom = true
      }
      if(type === 'video'){
        return this.video_zoom = item.url, this.zoom = true
      }
      if(type === 'close'){
        if( item.target.id !== 'video-zoom')
        {return this.img_zoom = null, this.video_zoom = null, this.zoom = false}
      }
    },
    async onDelete(type, item){
      this.$alert
        .fireConfirm("Borrar Medio Publico", "Esta seguro que desea continuar?")
        .then(() => {
         const id = item.id
         if(type === 'img'){
            this.$store.dispatch("deleteGaleriaPublicaIMG", id).then(()=>{
            this.getItems()
            });
          }
          if(type === 'video'){
             this.$store.dispatch("deleteGaleriaPublicaVIDEO", id).then(()=>{
            this.getItems()
            });
          }
        });
    },
    async onDeleteGaleriaPublica(){
      if(!this.form.selected_galeria_publica){
         return this.$toast.error("Debe Seleccionar Una Galeria Publica")
      }
      this.$alert
        .fireConfirm("Borrar Galeria Publica", "Esta seguro que desea continuar?")
        .then(() => {
         const id = this.form.selected_galeria_publica
          this.$store.dispatch("deleteGaleriaPublica", id).then(()=>{
            this.getItems()
          });
        });
    },
    async saveData(){
      if(!this.form_img.length && !this.form_video.length){
        return this.$toast.error("Debe Seleccionar Alguna Imagen o Video")
      }
      if(!this.form.new_galeria_id && !this.form.selected_galeria){
        return this.$toast.error("Debe Seleccionar o Crear Una Galeria")
      }
      let id = this.form.new_galeria_id || this.form.selected_galeria
      let form = {}
      form.img = this.form_img
      form.video = this.form_video
       await this.$store.dispatch('moveFilesPublicas',{id, form})
       .then(()=>{
        this.$emit('saved')
        this.close();
       })
    }
  },
};
</script>
<style lang="scss" scoped>
.card{
  width: 100%;
}
.button-back-img, .button-back-video{
  z-index: 5;
  left: 0%;
  top: 50%;
  transform: translateX(0%) translateY(-50%);
  filter: drop-shadow(3px 5px 2px rgb(0, 0, 0 / 0.4));
}
.button-forward-img, .button-forward-video{
  z-index: 5;
  right: 0%;
  top: 50%;
  transform: translateX(0%) translateY(-50%);
  filter: drop-shadow(3px 5px 2px rgb(0, 0, 0 / 0.4));
}

.style-galeria-img{
  height: 50px;
  width: auto;
}
.style-galeria-video{
  height: 50px;
  width: auto;
}
.style-galeria-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
   cursor: pointer;
}
.style-galeria-video video{
  height: 100%;
  width: 100%;
  object-fit: cover;
   cursor: pointer;
}
.style-galeria-img:hover > img, .style-galeria-video:hover > video{
  filter: blur(10px);
}
.style-galeria-img:hover > .icon-trash-delete-img, .style-galeria-video:hover > .icon-trash-delete-video,
.style-galeria-img:hover > .icon-search-img, .style-galeria-video:hover > .icon-search-video{
  z-index: 1;
}

.icon-trash-delete-img, .icon-trash-delete-video{
  font-weight: 1000;
  border-radius: 50%;
  border: 4px solid var(--color-primary);
  padding: 1em;
  // position: relative; 
  left: 20%;
  top: 50%;
  color: var(--color-primary);
  transform: translateX(-20%) translateY(-50%);
  z-index: -1;
  transition: all 0.5s;
  scale: 0.5;
}
.checkbox-size{
  left: -20%;
  top: 50%;
  transform: translateX(-20%) translateY(-50%);
}

.icon-trash-delete-img:hover, .icon-trash-delete-video:hover, .icon-search-img:hover, .icon-search-video:hover{
  color: white;
  border: 4px solid white;
  background-color: var(--color-primary);
  scale: 0.8;
  filter: drop-shadow(3px 5px 2px rgb(0, 0, 0 / 0.4));
}

.icon-search-img, .icon-search-video{
  font-weight: 1000;
  border-radius: 50%;
  border: 4px solid var(--color-primary);
  padding: 1em;
  left: 80%;
  top: 50%;
  color: var(--color-primary);
  transform: translateX(-80%) translateY(-50%);
  z-index: -1;
  transition: all 0.5s;
  scale: 0.5;
}

.zoom-items{
  z-index: 5;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: grid;
  place-items: center;
}
.zoom-items-img{
  max-height: 70vh;
  max-width: 70vw;
}
.zoom-items-video{
  max-height: 50vh;
  max-width: 50vw;
}

@media screen and (max-width: 600px){
  .media-600{
    flex-direction: column;
  }
}
</style>