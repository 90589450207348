<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Galerías Publicas</template>

        <!-- <button v-if="canPost" class="btn btn-block btn-medium" @click="createItem">
          Nueva Galería
        </button> -->
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="actions"
          :options="vTable.options"
          ref="vtable"
          :empty_message="canGet ? `No hay galerias para mostrar` : `No tienes permisos para ver galerias`"
          @changed="getItems"
          @onEditItem="onEditItem"
          @onDeleteItem="deleteItem"
          @onShowItem="onShowItem"
        />
        
        <div v-if="CheckRoles.SysAdmin === this.$store.getters.user.role_id" class="text-center mt-3"><button class="btn btn-dark float-right" 
          @click="goToEvents()">View Details</button></div>
      </Widget>
    </div>

    <GaleriaModal
      v-if="showModal"
      @close="closeModal"
      :id="selItem"
      @saved="onSaveItem"
    />
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import GaleriaModal from "./GaleriaPublicaModal.vue";
import {can} from '@/acl/methods'
import {CheckRoles} from '@/acl/constants.js'

export default {
  components: {
    Widget,
    GaleriaModal,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "socio",
          mask: "Socio",
        },
        {
          title: "count_galerias",
          mask: "Cantidad de Galerias",
        },
      ],
      actions: [
        // {
        //   buttonClass: "btn-warning",
        //   callback: "onEditItem",
        //   tooltip: "Editar Galería",
        //   icon: "fa-solid fa-pencil",
        // },
        {
          buttonClass: "btn-info",
          callback: "onShowItem",
          tooltip: "Ver Galería",
          icon: "fa-solid fa-eye",
        },
        // {
        //   buttonClass: "btn-danger",
        //   callback: "onDeleteItem",
        //   tooltip: "Borrar Noticia",
        //   icon: "fa-solid fa-trash",
        // },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    CheckRoles(){
      return CheckRoles
    },
    items() {
      return this.$store.getters.galeriasPublicasByName;
    },
    canGet(){
      return can(this.$store.getters.user, 'galerias', ['get'])
    },
    canPost(){
      return can(this.$store.getters.user, 'galerias', ['post'])
    },
    canPut(){
      return can(this.$store.getters.user, 'galerias', ['put'])
    },
    canDel(){
      return can(this.$store.getters.user, 'galerias', ['delete'])
    },
    actions(){
      const actions = this.vTable.actions
      if (!this.canPut) {
        actions.splice(actions.findIndex(a => a.callback === 'onEditItem'), 1)
      }
      if(!this.canGet){
        actions.splice(actions.findIndex(a => a.callback === 'onShowItem'), 1)
      }
      if (!this.canDel) {
        actions.splice(actions.findIndex(a => a.callback === 'onDeleteItem'), 1)
      }
      return actions
    }
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    closeModal() {
      this.selItem = null;
      this.showModal = false;
    },
    getItems(params) {
       params.columnSQL = params.columnSQL.filter(x=>x !== 'count_galerias')
      this.$store.dispatch("getGaleriasPublicasGroupName", params);
    },
    onEditItem(item) {
      this.showModal = true;
      this.selItem = item.socio;
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item.socio;
    },
    createItem() {
      this.showModal = true;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    deleteItem(item) {
      this.$alert
        .fireConfirm("Borrar Galeria Publica", "Esta seguro que desea continuar?")
        .then(() => {
         const id = item.socio
          this.$store.dispatch("deleteGaleriaPublica", id);
        });
    },
    goToEvents(){
       this.$router.push('/galeriasPublicasDetails')
    },
  },
};
</script>
