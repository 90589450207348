<template>
  <div class="form-check">
    <input class="form-check-input text-primary" type="checkbox"  id="flexCheckDefault" @change="sendChecked($event.target.checked, item)">
    <label class="form-check-label" for="flexCheckDefault">
    {{ text_checkbox }}
    </label>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
defineProps({
  text_checkbox:{
    type: String,
    default: '',
  },
  item:{
    type: Object,
    default:()=>{
      return {}
    }
  },
})
const emit = defineEmits(['checkBox'])
const sendChecked = (event, item)=>{
  let value = event
  emit('checkBox',value, item )

}
</script>

<style lang="scss" scoped>

input[type=checkbox] {
    transform: scale(1.5);
}
</style>